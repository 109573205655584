/* eslint-disable */

import IMask from 'imask';
import Choices from 'choices.js';

export default (() => {

  const phonesFields          = document.querySelectorAll('input[data-mask^="phone"]');
  const datesFields           = document.querySelectorAll('input[data-mask="date"]');
  const customFieldsMask      = document.querySelectorAll('input[data-mask^="custom"]');
  const selects               = document.querySelectorAll('.form-select');



  Array.from(phonesFields).forEach((field) => {
    const customMask = field.getAttribute('data-mask').split('|')[1]
    const mask = customMask || '+00 000000000'
    field.imask = IMask(field, {
      mask,
      lazy: false
    });
  });

  Array.from(customFieldsMask).forEach((field) => {
    const mask = field.getAttribute('data-mask').split('|')[1];
    if(!mask){
      console.error(`Не указана маска у поля ${field.name}`)
      return
    }
    field.imask = IMask(field, {
      mask
    });
  })

  Array.from(datesFields).forEach((field) => {
    field.imask = IMask(field, {
      mask: Date,
      autofix: true,
      pattern: 'd/`m/`Y',
      format (date) {
        const day   = (`0${ date.getDate()}` ).slice(-2);
        const month = (`0${ date.getMonth() + 1}` ).slice(-2);
        const year  = date.getFullYear();
        const value = [day, month, year].join('/');
        return value;
      },
      parse (str) {
        const mdy  = str.split('/');
        const date = new Date( mdy[2], mdy[1] - 1, mdy[0] );
        return date;
      },

    })
  })
  Array.from(selects).forEach((item) => {
    item.choices = new Choices(item, {
      placeholder: true,
      searchEnabled: false,
      itemSelectText: '',
    });
  });

})();
